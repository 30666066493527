import React from 'react';
import { Workout } from './components/Workout/Workout'
import { Day } from './interfaces/interface'
import logo from './logo.svg'

import styles from "./App.module.scss"

function App() {
  return (
    <div>
      <img src={logo} className={styles.appLogo} alt="logo" />
      <Workout day={Day.Friday}/>
    </div>
  )
}

export default App;
