import React, { useState } from 'react'

import { Day } from '../../interfaces/interface'

// import styles from './Workout.scss'
import styles from './Workout.module.scss'

interface Props {
  day: Day
}

const dayArray = [
  Day.Monday,
  Day.Tuesday,
  Day.Wednesday,
  Day.Thursday,
  Day.Friday,
  Day.Saturday,
  Day.Sunday,
]

export const Workout = ({ day: initialDay }: Props) => {
  const [dayIndex, setDayIndex] = useState<number>(dayArray.indexOf(initialDay))

  const day = dayArray[dayIndex]

  const incrementDay = () => {
    setDayIndex((dayIndex + 1) % 7)
  }

  const decrementDay = () => {
    if (dayIndex === 0) {
      setDayIndex(6)
    } else {
      setDayIndex(dayIndex -1)
    }
  }

  return (
    <div className={styles.container}>
      <h1>Your workout for {day}</h1>
      <div>
        <button onClick={decrementDay}>{'<'}</button>
        <button onClick={incrementDay}>{'>'}</button>
      </div>
    </div>
  )
}
